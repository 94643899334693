#main {
	--link-color: #049ccf;
	--link-decoration: underline;
	grid-area: main;
}

#map {
	width: 100%;
}

leaflet-map:fullscreen {
	--map-height: 100vh;
	--map-width: 100vw;
}

leaflet-marker::part(popup) {
	max-height: 60vh;
	max-width: 60vw;
	overflow: auto;
}

@charset "UTF-8";
@import url("./vars.css");
@import url("https://cdn.kernvalley.us/css/normalize/normalize.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/rem.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/viewport.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/element.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/forms.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/class-rules.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/cdn-fonts.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/animations.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/scrollbar.css");
@import url("./layout.css");

img.avatar {
	vertical-align: middle;
	margin-left: 1.2em;
}

#search {
	padding: 0.4rem 1rem;
	background: #e8e8e8a3;
}

#settings-dialog[open] {
	min-width: 400px;
}

leaflet-marker:not(:defined) {
	display: none;
}

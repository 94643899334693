@import url("./header.css");
@import url("./nav.css");
@import url("./sidebar.css");
@import url("./main.css");
@import url("./footer.css");

/* @import url("https://cdn.kernvalley.us/css/core-css/layout/index.css"); */

body {
	display: grid;
	grid-template-areas: "nav nav nav" ". main sidebar" "footer footer footer";
	grid-template-rows: var(--nav-height, 4rem) 1fr auto;
	grid-template-columns: 12px 1fr 400px;
	gap: 8px;
}

@media (max-width: 980px) {
	:root {
		--map-height: calc(80vh - var(--nav-height, 4rem));
	}

	body {
		grid-template-areas: "nav" "main" "sidebar" "footer";
		grid-template-columns: 100%;
		grid-template-rows: var(--nav-height, 4rem) var(--map-height) auto auto;
		gap: 8px 0;
	}
}

button.btn[is="share-button"] {
	bottom: 1rem;
	right: 1rem;
	padding: 0.8rem;
	font-size: 1.5rem;
}

@media (max-width: 800px) {
	.mobile-hidden {
		display: none;
	}
}
